var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"admin-nav-bar"},[_c('ul',[_c('li',{class:_vm.$route.matched.some(function (ref) {
          var name = ref.name;

          return name === 'admin.user';
}) && 'active'},[_c('router-link',{attrs:{"to":"/admin/user"}},[_vm._v("사용자 관리")])],1),_c('li',{class:_vm.$route.matched.some(function (ref) {
          var name = ref.name;

          return name === 'admin.nft';
}) && 'active'},[_c('router-link',{attrs:{"to":"/admin/nft"}},[_vm._v("NFT 관리")])],1),_c('li',{class:_vm.$route.matched.some(function (ref) {
          var name = ref.name;

          return name === 'admin.comingsoon';
}) &&
          'active'},[_c('router-link',{attrs:{"to":"/admin/comingsoon"}},[_vm._v("ComingSoon 관리")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }