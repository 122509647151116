<template>
  <div class="admin-nav-bar">
    <ul>
      <li
        :class="
          $route.matched.some(({ name }) => name === 'admin.user') && 'active'
        "
      >
        <router-link to="/admin/user">사용자 관리</router-link>
      </li>
      <li
        :class="
          $route.matched.some(({ name }) => name === 'admin.nft') && 'active'
        "
      >
        <router-link to="/admin/nft">NFT 관리</router-link>
      </li>
      <li
        :class="
          $route.matched.some(({ name }) => name === 'admin.comingsoon') &&
            'active'
        "
      >
        <router-link to="/admin/comingsoon">ComingSoon 관리</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CreatorItem",
  computed: {
    isCurrentPage() {
      const url = "";
      if (url === "/admin/user") return 0;
      if (url === "/admin/nft") return 1;
      if (url === "/admin/comingsoon") return 2;
      else return -1;
    }
  }
};
</script>

<style scoped>
.admin-nav-bar {
  width: 200px;
  height: auto;
  background-color: #f1f1f1;
  border-top: 1px solid #f1f1f1;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;

  background-color: #f1f1f1;
}

li {
  color: #000;
  padding: 8px 0 8px 16px;
}

li a {
  text-decoration: none;
  color: black;
}

li.active {
  background-color: #ba7fd1;
}
li.active a {
  color: white;
  text-decoration: none;
}
</style>
