<template>
  <div class="admin-comingsoon-page">
    <admin-nav-bar></admin-nav-bar>
    <div class="container">
      <h1>ComingSoon 리스트 관리</h1>
      <p>ComingSoon 리스트 관리 메뉴입니다.</p>
      <div class="comingsoon-add-member">
        <input
          type="text"
          v-model="addUserId"
          placeholder="추가할 페이지 아이디(pageId)를 입력해 주세요."
        />
        <button class="add-member-btn" @click="onClickAddMember">
          추가하기
        </button>
      </div>
      <span class="danger">* pageId ex) cccv.to/one.j 에서 one.j 부분.</span>
      <div class="comingsoon-members">
        <h2>Comingsoon 목록</h2>
        <table>
          <tr>
            <th>No.</th>
            <th>닉네임</th>
            <th>설명(page_id)</th>
            <th>상세</th>
            <th>삭제</th>
          </tr>
          <tr v-if="userList.length === 0">
            <td colspan="5">검색 결과가 없습니다.</td>
          </tr>
          <tr v-for="item in userList" v-bind:key="item.index">
            <td>{{ `${parseInt(item.index) + 1}` }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.description }}</td>
            <td>
              <button class="table-btn" @click="onClickInformation(item)">
                보기
              </button>
            </td>
            <td>
              <button
                class="table-btn danger"
                @click="onClickDeleteMember(item)"
              >
                삭제
              </button>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <modal v-if="showModal" @close="handleCloseModal">
      <template v-slot:header>
        <h1>사용자 정보</h1>
      </template>
      <template v-slot:body>
        <h3>닉네임</h3>
        <p>{{ modalData.name }}</p>
        <h3>프로필 이미지</h3>
        <p><img class="modal-profile-img" :src="modalData.profileImgUrl" /></p>
        <h3>자기소개</h3>
        <p>{{ modalData.message }}</p>
        <h3>sns 정보</h3>
        <div
          class="sns-modal-container"
          v-for="sns in modalData.sns"
          v-bind:key="sns.code"
        >
          <h5>{{ sns.code }}</h5>
          <p>{{ sns.url }}</p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {
  COMINGSOON_CURATION_TITLE,
  getUserInfo,
  setComingsoonMember
} from "@/common/api.service";
import { checkAdmin } from "@/common/auth.service";
import AdminNavBar from "@/components/admin/Navbar";
import Modal from "@/components/admin/Modal";
import { getCurationListByTitle } from "../../common/api.service";

export default {
  name: "User",
  components: {
    AdminNavBar,
    Modal
  },
  data() {
    return {
      addUserId: "",
      showModal: false,
      modalData: {},
      userList: []
    };
  },
  async created() {
    const isAdmin = await checkAdmin();

    if (!isAdmin) {
      this.$router.push("/");
    }

    // 젤리크루 큐레이션 리스트 생성
    // createCurationList(
    //   COMINGSOON_CURATION_TITLE,
    //   "젤리크루 ComingSoon Section 표시를 위한 큐레이션",
    //   "users"
    // );

    this.getComingSoonList();
  },
  methods: {
    getComingSoonList() {
      getCurationListByTitle(COMINGSOON_CURATION_TITLE).then(res => {
        if (res && res.data) {
          this.userList =
            res.data.items.sort((a, b) => {
              return a.index - b.index;
            }) ?? [];
        }
      });
    },
    addComingsoonParameters() {
      //기존 가지고 있던 Parameter에 새로운 유저 아이디 추가
      return [
        ...this.userList.map((o, idx) => {
          return {
            _id: o.description,
            description: o.description,
            index: idx
          };
        }),
        {
          _id: this.addUserId,
          description: this.addUserId,
          index: this.userList.length
        }
      ];
    },
    removeComingsoonParameters(data) {
      return this.userList
        .filter(o => o._id !== data._id)
        .map((o, idx) => {
          return {
            _id: o.description,
            description: o.description,
            index: idx
          };
        });
    },
    updateComingsoonMember(params) {
      setComingsoonMember(COMINGSOON_CURATION_TITLE, params).then(res => {
        if (res && res.data) {
          this.getComingSoonList();
          this.addUserId = "";
        }
      });
    },
    onClickAddMember() {
      if (!this.addUserId) {
        alert("아이디를 입력해 주세요.");
        return;
      }

      if (this.userList.length >= 5) {
        alert("최대 5개까지 추가 가능합니다.");
        return;
      }

      const params = this.addComingsoonParameters();
      this.updateComingsoonMember(params);
    },
    onClickDeleteMember(data) {
      if (!window.confirm("ComingSoon 리스트에서 제거하시겠습니까?")) return;

      const params = this.removeComingsoonParameters(data);
      this.updateComingsoonMember(params);
    },
    onClickInformation(data) {
      getUserInfo(data.description).then(res => {
        if (res && res.data) {
          this.showModal = true;
          this.modalData = res.data[0];
        }
      });
    },
    handleCloseModal() {
      this.showModal = false;
      this.modalData = {};
    }
  }
};
</script>

<style scoped>
.admin-comingsoon-page {
  display: flex;
  border-top: 1px solid #f1f1f1;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.comingsoon-members {
  width: 100%;
}

.comingsoon-add-member {
  display: flex;
  margin-bottom: 10px;
}

.comingsoon-add-member input {
  width: 250px;
}

.add-member-btn {
  margin-left: 15px;
  background-color: #ba7fd1;
  border: none;
  color: white;
  height: 30px;
  width: 100px;
  cursor: pointer;
}

.modal-profile-img {
  max-width: 200px;
}
</style>
